<template>
  <div class="bg">
    <div class="login wp">
      <vue-particles
        color="#fff"
        :particleOpacity="0.3"
        :particlesNumber="60"
        shapeType="circle"
        :particleSize="4"
        linesColor="#fff"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="2"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
        class="lizi"
        style="z-index: -1"
      >
      </vue-particles>
      <div v-if="sign == 1" class="boardLogin f16">
        <div class="login_title f25 col-f2e">
          <span slot="label" class="title"> 账号密码登录</span>
          <span class="f12" @click="sign = 2" style="cursor: pointer"
            >免费注册</span
          >
        </div>
        <form ref="form">
          <div class="inpGroup">
            <span class="loginIco1"></span>
            <input
              type="tel"
              v-model.number="form.user_loginname"
              placeholder="请输入手机号"
              @blur="isPhone(form)"
              maxlength="11"
              @keyup.enter="submitForm('form')"
            />
          </div>
          <div class="prompt">
            <p class="error">手机号格式不对</p>
          </div>

          <div class="inpGroup">
            <span class="loginIco2"></span>
            <input
              type="password"
              placeholder="请输入密码"
              v-model="form.user_loginpass"
              @blur="isPassword(form)"
              maxlength="15"
              @keyup.enter="submitForm('form')"
            />
          </div>
          <div class="prompt">
            <p class="success">输入正确</p>
          </div>
          <div class="fr">
            <span class="col-f2e f12" @click="sign = 3" style="cursor: pointer"
              >忘记密码？</span
            >
          </div>
          <!-- <button class="submit">登录</button> -->
          <a class="animsition-link submit" @click="submitForm('form')">登录</a>
        </form>
      </div>
      <!--        <el-tabs v-if='sign==1' type="border-card" v-model="activeName"  @tab-click="handleClick">-->

      <!--          <el-tab-pane name="1"><span slot="label"><i class="iconfont iconzhanghaoxiangguan"></i> 账号密码登录</span>-->
      <!--            <div class="contain">-->
      <!--              <div class="login-contain mt20" ref="Purple">-->
      <!--                <el-form autocomplete="off" class="contain_right tl" label-position="top" label-width="80px" ref="form"-->
      <!--                  :model="form">-->
      <!--                  <div class="title cac fb">-->
      <!--                    密码登录-->
      <!--                  </div>-->

      <!--                  <el-form-item class="cac mt20" label="" prop="user_loginname"-->
      <!--                    :rules="[{type: 'number',message: '请输入手机号',trigger: 'blur'},{pattern: /^1[3|4|5|7|8|9][0-9]\d{8}$/,message: '手机号格式不对',trigger: 'blur'}]">-->
      <!--                    <el-input size="large" id="aaa" placeholder="请输入手机号" maxlength="11" clearable-->
      <!--                      v-model.number="form.user_loginname"></el-input>-->
      <!--                  </el-form-item>-->
      <!--                  <el-form-item label="" prop="user_loginpass"-->
      <!--                    :rules="[{required: true,message: '请输入密码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9]+$/, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'},{ min: 4, max: 15, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'}]">-->
      <!--                    <el-input size="large" type="password" placeholder="请输入密码" clearable v-model="form.user_loginpass">-->
      <!--                    </el-input>-->
      <!--                  </el-form-item>-->
      <!--                  <el-form-item class="mt15 mb15">-->
      <!--                    <el-button round style="width:100%" type="primary" @click="submitForm('form')">登录</el-button>-->

      <!--                  </el-form-item>-->
      <!--                  <p class="tr fr f12 mr20 disib forget_btn" @click="sign=3" style="cursor:pointer;">忘记密码</p>-->
      <!--                  <p class="tr f12 fr mr10 disib forget_btn" @click="sign=2" style="cursor:pointer;">免费注册</p>-->
      <!--                </el-form>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </el-tab-pane>-->
      <!--          &lt;!&ndash; <el-tab-pane name="2">-->
      <!--            <span slot="label"><i class="iconfont iconerweima"></i> 扫码登录</span>-->
      <!--            <div class="contain">-->
      <!--              <div class="login-contain mt20" ref="Purple">-->
      <!--                <el-form autocomplete="off" class="contain_right tl" label-position="top" label-width="80px" ref="ruleForm"-->
      <!--                  :model="form">-->
      <!--                  <div class="tc">-->
      <!--                    <div style="" id="weixin"></div>-->
      <!--                  </div>-->

      <!--                </el-form>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </el-tab-pane> &ndash;&gt;-->

      <!--        </el-tabs>-->
      <div v-if="sign == 2" class="boardLogin f16">
        <div class="login_title f25 col-f2e">
          <span slot="label" class="title"> 注册</span>
          <span class="f12" @click="sign = 1" style="cursor: pointer"
            >已有账号？登录</span
          >
        </div>
        <form>
          <div class="inpGroup">
            <span class="loginIco1"></span>
            <input
              type="tel"
              v-model.number="twoForm.user_loginname"
              placeholder="请输入手机号"
              maxlength="11"
              @blur="isPhone(twoForm)"
            />
          </div>
          <div class="prompt">
            <p class="error"></p>
          </div>

          <div class="inpGroup">
            <span class="loginIco2"></span>
            <input
              type="password"
              placeholder="请输入密码"
              v-model="twoForm.user_loginpass"
              @blur="isPassword(twoForm)"
            />
          </div>
          <div class="prompt"></div>
          <div class="inpGroup">
            <span class="loginIco2"></span>
            <input
              type="password"
              placeholder="请确认输入密码"
              v-model="twoForm.user_loginpass_re"
              @blur="isSamePassword()"
            />
          </div>
          <div class="prompt"></div>
          <JcRange
            class="mb20"
            status="ruleForm.status"
            :successFun="onMpanelSuccess"
            :errorFun="onMpanelError"
            >--></JcRange
          >
          <div class="inpGroup">
            <span class=""></span>
            <input
              type="number"
              placeholder="请输入验证码"
              v-model="twoForm.mess_code"
            />
            <a class="button_code" style="cursor: pointer" @click="takeNum">
              <span v-show="show">获取验证码</span>
              <span v-show="!show" class="count">{{ count }} s</span>
            </a>
          </div>
          <a class="animsition-link submit" @click="submitForm1('twoForm')"
            >注册</a
          >
        </form>
      </div>
      <!--        <el-tabs v-else-if="sign==2" type="border-card">-->
      <!--          <div class="contain">-->
      <!--            <div class="login-contain mt20" ref="Purple">-->
      <!--              <el-form autocomplete="off" class="contain_right tl" label-position="top" label-width="80px" ref="twoForm"-->
      <!--                :model="twoForm">-->
      <!--                <div class="title cac f25 fb">-->
      <!--                  欢迎注册-->
      <!--                </div>-->
      <!--                <p class="f12 mt10" @click="show_div='2'" style="cursor:pointer;">已有账号？<span class="forget_btn "-->
      <!--                    @click="sign=1">登录</span></p>-->
      <!--                <el-form-item class="cac mt20" label="" prop="user_loginname"-->
      <!--                  :rules="[{type: 'number',message: '请输入手机号',trigger: 'blur'},{pattern: /^1[3|4|5|7|8|9][0-9]\d{8}$/,message: '手机号格式不对',trigger: 'blur'}]">-->
      <!--                  <el-input size="large" id="aaa" placeholder="请输入手机号" maxlength="11" clearable-->
      <!--                    v-model.number="twoForm.user_loginname"></el-input>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item label="" prop="user_loginpass"-->
      <!--                  :rules="[{required: true,message: '请输入密码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9]+$/, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'},{ min: 4, max: 15, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'}]">-->
      <!--                  <el-input size="large" type="password" placeholder="请输入密码" clearable v-model="twoForm.user_loginpass">-->
      <!--                  </el-input>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item label="" prop="user_loginpass_re"-->
      <!--                  :rules="[{required: true,message: '请输入确认密码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9]+$/, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'},{ min: 4, max: 15, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'}]">-->
      <!--                  <el-input size="large" type="password" placeholder="请输入确认密码" clearable-->
      <!--                    v-model="twoForm.user_loginpass_re"></el-input>-->
      <!--                </el-form-item>-->
      <!--                <JcRange class="mb20" status="ruleForm.status" :successFun="onMpanelSuccess" :errorFun="onMpanelError">-->
      <!--                </JcRange>-->
      <!--                <el-form-item class="cac " label="" prop="mess_code">-->
      <!--                  <el-input id="aaa" placeholder="请输入验证码" clearable v-model="twoForm.mess_code">-->
      <!--                    &lt;!&ndash; <el-button  slot="append" type="primary" @click="takeNum">获取验证码</el-button> &ndash;&gt;-->
      <!--                    <el-button icon="el-icon-mobile-phone" @click="takeNum('twoForm')" slot="append" type="primary"-->
      <!--                      :disabled="disabled=!show">-->
      <!--                      <span v-show="show">获取验证码</span>-->
      <!--                      <span v-show="!show" class="count">{{count}} s</span>-->
      <!--                    </el-button>-->
      <!--                  </el-input>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item class="mt15">-->
      <!--                  <el-button round style="width:100%" type="primary" @click="submitForm1('twoForm')">注册</el-button>-->

      <!--                </el-form-item>-->
      <!--              </el-form>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </el-tabs>-->

      <div v-if="sign == 3" class="boardLogin f16">
        <div class="login_title f25 col-f2e">
          <span slot="label" class="title"> 忘记密码</span>
          <span class="f12" @click="sign = 1" style="cursor: pointer"
            >返回登录</span
          >
        </div>
        <form>
          <div class="inpGroup">
            <span class="loginIco1"></span>
            <input
              type="tel"
              v-model.number="twoForm.user_loginname"
              placeholder="请输入手机号"
              maxlength="11"
              @blur="isPhone(twoForm)"
            />
          </div>
          <div class="prompt">
            <p class="error">用户名错误或不存在</p>
          </div>

          <div class="inpGroup">
            <span class="loginIco2"></span>
            <input
              type="password"
              placeholder="请输入密码"
              v-model="twoForm.user_loginpass"
              @blur="isPassword(twoForm)"
            />
          </div>
          <div class="prompt"></div>
          <div class="inpGroup">
            <span class="loginIco2"></span>
            <input
              type="password"
              placeholder="请确认输入密码"
              v-model="twoForm.user_loginpass_re"
              @blur="isSamePassword()"
            />
          </div>
          <div class="prompt"></div>
          <JcRange
            class="mb20"
            status="ruleForm.status"
            :successFun="onMpanelSuccess"
            :errorFun="onMpanelError"
            >--></JcRange
          >
          <div class="inpGroup">
            <span class=""></span>
            <input
              type="number"
              placeholder="请输入验证码"
              v-model="twoForm.mess_code"
            />
            <a class="button_code" style="cursor: pointer" @click="takeNum">
              <span v-show="show">获取验证码</span>
              <span v-show="!show" class="count">{{ count }} s</span>
            </a>
          </div>
          <a class="animsition-link submit" @click="submitForm2('twoForm')"
            >提交</a
          >
        </form>
      </div>
      <!--        <el-tabs v-else-if="sign==3" type="border-card">-->
      <!--          <div class="contain">-->
      <!--            <div class="login-contain mt20" ref="Purple">-->
      <!--              <el-form autocomplete="off" class="contain_right tl" label-position="top" label-width="80px" ref="twoForm"-->
      <!--                :model="twoForm">-->
      <!--                <div class="title cac f25 fb">-->
      <!--                  忘记密码-->
      <!--                </div>-->
      <!--                <p class="f12 mt10" @click="show_div='2'" style="cursor:pointer;">返回&nbsp;<span class="forget_btn "-->
      <!--                    @click="sign=1">登录</span></p>-->
      <!--                <el-form-item class="cac mt20" label="" prop="user_loginname"-->
      <!--                  :rules="[{type: 'number',message: '请输入手机号',trigger: 'blur'},{pattern: /^1[3|4|5|7|8|9][0-9]\d{8}$/,message: '手机号格式不对',trigger: 'blur'}]">-->
      <!--                  <el-input size="large" id="aaa" placeholder="请输入手机号" maxlength="11" clearable-->
      <!--                    v-model.number="twoForm.user_loginname"></el-input>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item label="" prop="user_loginpass"-->
      <!--                  :rules="[{required: true,message: '请输入密码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9]+$/, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'},{ min: 4, max: 15, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'}]">-->
      <!--                  <el-input size="large" type="password" placeholder="请输入新密码" clearable v-model="twoForm.user_loginpass">-->
      <!--                  </el-input>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item label="" prop="user_loginpass_re"-->
      <!--                  :rules="[{required: true,message: '请输入确认新密码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9]+$/, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'},{ min: 4, max: 15, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'}]">-->
      <!--                  <el-input size="large" type="password" placeholder="请输入确认新密码" clearable-->
      <!--                    v-model="twoForm.user_loginpass_re"></el-input>-->
      <!--                </el-form-item>-->
      <!--                <JcRange class="mb20" status="ruleForm.status" :successFun="onMpanelSuccess" :errorFun="onMpanelError">-->
      <!--                </JcRange>-->
      <!--                <el-form-item class="cac " label="" prop="mess_code">-->
      <!--                  <el-input id="aaa" placeholder="请输入验证码" clearable v-model="twoForm.mess_code">-->
      <!--                    &lt;!&ndash; <el-button  slot="append" type="primary" @click="takeNum">获取验证码</el-button> &ndash;&gt;-->
      <!--                    <el-button icon="el-icon-mobile-phone" @click="takeNum('twoForm')" slot="append" type="primary"-->
      <!--                      :disabled="disabled=!show">-->
      <!--                      <span v-show="show">获取验证码</span>-->
      <!--                      <span v-show="!show" class="count">{{count}} s</span>-->
      <!--                    </el-button>-->
      <!--                  </el-input>-->
      <!--                </el-form-item>-->
      <!--                <el-form-item class="mt15">-->
      <!--                  <el-button round style="width:100%" type="primary" @click="submitForm2('twoForm')">提交</el-button>-->

      <!--                </el-form-item>-->
      <!--              </el-form>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </el-tabs>-->
      <div
        v-if="platform"
        class="admin-content-footer f14 z2"
        style="
          position: fixed;
          bottom: 5px;
          right: 20px;
          z-index: 2;
          color: #aaa;
        "
      >
        <div>{{ platform.platform_record }}</div>
        <div>
          ©<span id="yearid">{{ platform.platform_copyright }}</span>
          {{ platform.platform_main_title }}&nbsp; &nbsp;{{
            platform.platform_sub_title
          }}&nbsp; &nbsp;版权所有：{{ platform.platform_support }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import md5 from "js-md5";
import axios from "../util/api";
import thirdAxios from "axios";
import { local } from "../util/util";
import JcRange from "@/components/JcRange.vue";
export default {
  components: {
    JcRange,
  },
  mounted() {
    if (this.activeName == "2") {
      var obj = new WxLogin({
        self_redirect: false,
        id: "weixin",
        appid: "wx02bfca0fe5505c6d",
        scope: "snsapi_login",
        redirect_uri: "http://efr.foodtrace.org.cn/pc/login",
        state: "",
        style: "",
        href: "",
      });
    }
    //绑定事件
    // window.addEventListener('keydown',this.keyDown);
  },
  watch: {
    activeName: function () {},
  },
  data() {
    var checkStatus = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请拖动滑块完成验证"));
      } else {
        callback();
      }
    };
    return {
      show_div: "1",
      sign: 1,
      activeName: "1",
      form: {
        user_loginname: "",
        user_loginpass: "",
        verified_name: false,
        verified_pw: false,
      },
      twoForm: {
        user_loginname: "",
        user_loginpass: "",
        user_loginpass_re: "",
        query_param: "",
        mess_code: "",
        verified_name: false,
        verified_pw: false,
        isSamePw: false,
      },
      url: "",
      show: true,
      count: "",
      timer: null,
      user: "",
      ruleForm: {
        status: [{ validator: checkStatus, trigger: "change" }],
      },
      user_id: "",
      company_name: "",
      platform: "",
      code: "",
      user_region: "",
    };
  },
  created() {
    let getToken = local.get("token");
    if (getToken) {
      this.autoLogin();
    }
    this.getPlatform();
    this.code = this.$route.query.code;
    // console.log(123, "试验", this.code);
    // this.code='031ogT000X8RQK1jF0100rM2fk2ogT0j'
    if (this.code) {
      this.silentLogin(this.code);
    }
  },
  methods: {
    //验证手机号格式
    isPhone(form) {
      if (
        null === form.user_loginname ||
        "" === form.user_loginname ||
        undefined === form.user_loginname
      ) {
        this.$message.warning("手机号不能为空");
      } else {
        let RegCellPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        let falg = form.user_loginname.toString().search(RegCellPhone);
        if (falg === -1) {
          this.$message.warning("手机号格式不正确");
          form.verified_name = false;
        } else {
          form.verified_name = true;
        }
      }
    },
    //验证密码格式
    isPassword(form) {
      if (
        null === form.user_loginpass ||
        "" === form.user_loginpass ||
        undefined === form.user_loginpass
      ) {
        this.$message.warning("密码不能为空");
      } else {
        if (form.user_loginpass.length < 4 || form.user_loginpass.length > 15) {
          this.$message.warning("密码长度为4-15数字或字母组成");
          form.verified_pw = false;
          return;
        }
        let RegCellPhone = /^[A-Za-z0-9]+$/;
        let falg = form.user_loginpass.toString().search(RegCellPhone);
        if (falg === -1) {
          this.$message.warning("密码格式不正确,由4-15数字或字母组成");
          form.verified_pw = false;
        } else {
          //console.log("密码验证ok")
          form.verified_pw = true;
        }
      }
    },
    //验证两次密码是否一致
    isSamePassword() {
      if (this.twoForm.user_loginpass !== this.twoForm.user_loginpass_re) {
        this.$message.warning("两次输入的密码不一致");
      } else {
        this.twoForm.isSamePw = true;
      }
    },
    //回车登录
    //  keyDown(e){
    //    //如果是回车则执行登录方法
    //    if(e.keyCode == 13){
    //      alert("请点击登录按钮!");
    //    }
    //  },
    onMpanelSuccess() {
      this.twoForm.query_param = "2fb1c5cf58867b5bbc9a1b145a86f3a0";
    },
    onMpanelError() {
      this.twoForm.query_param = "";
    },
    getPlatform() {
      axios.get("/pc/platform/single", { platform_id: "1" }).then((v) => {
        this.platform = v.data.platform;
      });
    },
    //自动登录
    autoLogin() {
      axios
        .get("/pc/user/autologin")
        .then((v) => {
          local.set("user_loginname", this.form.user_loginname);
          axios.get("/pc/user/single").then((response) => {
            this.user = response.data.user;
            local.set("user", response.data.user);
            local.set("company_id", response.data.user.last_login_company_id);
            if (response.data.user.user_type == 3) {
              if (v.data.complete_state == "234") {
                this.isMenu = 1;
                local.set("isMenu", 1);
                this.$router.push({ name: "Public" });
              } else if (v.data.complete_state == "235") {
                this.isMenu = 2;
                local.set("isMenu", 2);
                this.getCompany(
                  String(response.data.user.last_login_company_id)
                );
                this.getMenu(response.data.user.last_login_company_id);
              }
            } else if (response.data.user.user_type == 4) {
              this.isMenu = 2;
              local.set("isMenu", 2);
              this.getCompany1(
                String(response.data.user.last_login_company_id)
              );
              this.getMenu(response.data.user.last_login_company_id);
            } else {
              this.isMenu = 2;
              local.set("isMenu", 2);
              this.getMenu(response.data.user.last_login_company_id);
            }
          });
        })
        .catch();
    },
    //提交登录
    submitForm() {
      this.isPassword(this.form);
      if (!this.form.user_loginpass || !this.form.user_loginname) {
        this.$message.warning("请先填写完成！");
      } else if (!this.form.verified_name || !this.form.verified_pw) {
        this.$message.warning("填写的格式有误！");
      } else {
        this.login();
      }

      // this.$refs[form].validate((valid) => {
      //   if (valid) {
      //     this.login()
      //   } else {
      //     return false
      //   }
      // })
    },
    silentLogin(code) {
      const that = this;
      thirdAxios
        .get("http://cllext.foodtrace.org.cn/get_phone", {
          params: {
            code,
          },
        })
        .then(function (response) {
          const { data } = response;
          if (data) {
            that.form.user_loginname = data.result;
            that.form.user_loginpass = "888888";
            that.user_region = "5117";
            if (data.result) that.login();
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    login() {
      axios
        .post("/pc/user/login", {
          user_loginname: this.form.user_loginname,
          user_loginpass: md5(this.form.user_loginpass),
        })
        .then((v) => {
          local.set("token", v.data.token);
          local.set("user_loginname", this.form.user_loginname);
          local.set("user_region", this.user_region);
          axios.get("/pc/user/single").then((response) => {
            this.user = response.data.user;
            local.set("user", response.data.user);
            local.set("company_id", response.data.user.last_login_company_id);
            if (response.data.user.user_type == 3) {
              if (v.data.complete_state == "234") {
                this.isMenu = 1;
                local.set("isMenu", 1);
                this.$router.push({ name: "Public" });
              } else if (v.data.complete_state == "235") {
                this.isMenu = 2;
                local.set("isMenu", 2);
                this.getCompany(
                  String(response.data.user.last_login_company_id)
                );
                this.getMenu(response.data.user.last_login_company_id);
              }
            } else if (response.data.user.user_type == 4) {
              this.isMenu = 2;
              local.set("isMenu", 2);
              this.getCompany1(
                String(response.data.user.last_login_company_id)
              );
              this.getMenu(response.data.user.last_login_company_id);
            } else {
              this.isMenu = 2;
              local.set("isMenu", 2);
              this.getMenu(response.data.user.last_login_company_id);
            }
          });
        })
        .catch((v) => {
          this.$message.error("网络异常");
        });
    },
    //获取验证码
    takeNum(twoForm) {
      if (this.twoForm.user_loginpass !== this.twoForm.user_loginpass_re) {
        this.$message.warning("两次输入密码不一致，请重新输入");
        return false;
      }
      if (!this.twoForm.user_loginname || !this.twoForm.user_loginpass) {
        this.$message.warning("请先填写完成");
        return false;
      }
      if (this.twoForm.query_param == "") {
        this.$message.error("滑块验证不成功");
        return false;
      }
      let mess_type = this.sign == 2 ? "1" : "2";
      let new_user_loginname = this.twoForm.user_loginname + "";
      let myThis = this;
      //this.$refs[twoForm].validate((valid) => {
      this.twoForm.mess_code = "";
      //if (valid) {
      if (this.sign == 2) {
        axios
          .get("/pc/user/exist", { user_loginname: new_user_loginname })
          .then((v) => {
            if (v.code === 200) {
              this.messget(new_user_loginname, mess_type);
            } else {
              this.$message.error("该用户不存在");
            }
          })
          .catch();
      } else {
        this.messget(new_user_loginname, mess_type);
      }

      // } else {
      //   return false
      // }
      //})
    },
    messget(loginname, type) {
      axios
        .get("/pc/sms/send", { user_mobilephone: loginname, mess_type: type })
        .then((v) => {
          this.send();
          this.user_id = v.data.user_id;
        })
        .catch();
    },
    send() {
      let TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    submitForm1(twoForm) {
      //this.$refs[twoForm].validate((valid) => {
      //if (valid) {
      if (
        !this.twoForm.user_loginname ||
        !this.twoForm.user_loginpass ||
        !this.twoForm.user_loginpass_re
      ) {
        this.$message.warning("请填写完成");
        return false;
      }
      if (!this.twoForm.isSamePw) {
        this.$message.warning("两次填写的密码不一致");
        return false;
      }
      if (!this.twoForm.verified_name || !this.twoForm.verified_pw) {
        this.$message.warning("填写的格式有误");
        return false;
      }
      if (this.twoForm.mess_code == "") {
        this.$message.warning("请填写验证码");
        return false;
      }
      axios
        .post("/pc/user/register", {
          user_loginname: this.twoForm.user_loginname,
          user_loginpass: md5(this.twoForm.user_loginpass),
          user_loginpass_re: md5(this.twoForm.user_loginpass_re),
          mess_code: this.twoForm.mess_code,
          query_param: this.twoForm.query_param,
        })
        .then((v) => {
          this.form.user_loginname = this.twoForm.user_loginname;
          this.form.user_loginpass = this.twoForm.user_loginpass;
          this.login();
        })
        .catch((v) => {
          this.$message.error("网络异常");
        });
      // } else {
      //   return false
      // }
      //})
    },
    submitForm2(twoForm) {
      //this.$refs[twoForm].validate((valid) => {
      //if (valid) {
      if (
        !this.twoForm.user_loginname ||
        !this.twoForm.user_loginpass ||
        !this.twoForm.user_loginpass_re
      ) {
        this.$message.warning("请填写完成");
        return false;
      }
      if (!this.twoForm.isSamePw) {
        this.$message.warning("两次填写的密码不一致");
        return false;
      }
      if (!this.twoForm.verified_name || !this.twoForm.verified_pw) {
        this.$message.warning("填写的格式有误");
        return false;
      }
      if (this.twoForm.mess_code == "") {
        this.$message.warning("请填写验证码");
        return false;
      }
      axios
        .put("/pc/user/password/forget", {
          user_loginname: this.twoForm.user_loginname,
          user_id: this.user_id,
          user_loginpass: md5(this.twoForm.user_loginpass),
          user_loginpass_re: md5(this.twoForm.user_loginpass_re),
          mess_code: this.twoForm.mess_code,
          query_param: this.twoForm.query_param,
        })
        .then((v) => {
          this.form.user_loginname = this.twoForm.user_loginname;
          this.form.user_loginpass = this.twoForm.user_loginpass;
          this.login();
        });
      // } else {
      //   return false
      // }
      //})
    },
    //获取菜单
    getMenu(params) {
      var that = this;
      axios.get("/pc/user-role/menu").then((v) => {
        if (v.data.menu_list == null || v.data.menu_list.length == 0) {
          this.isMenu = 1;
          local.set("isMenu", 1);
          this.$router.push({ name: "Public" });
        } else {
          that.url = v.data.menu_list[0].menu_list[0].menu_url;
          that.$router.replace({
            path: that.url,
          });
          that.menu_list = v.data.menu_list.map((v) => {
            // console.log(v, 321);
            let data = {
              ...v,
              menu_list: v.menu_list
                .map((item) => {
                  let menu_list = null;
                  if (
                    item.menu_url == "/admin/sensorFuSunScreen" &&
                    local.get("company_id") != 2582225
                  ) {
                    // console.log(item.menu_url, '测试富顺');
                  } else if (
                    item.menu_url == "/admin/sensorPingWuScreen" &&
                    local.get("company_id") != 2582275
                  ) {
                    // menu_list = item;
                    // console.log(item.menu_url, '测试平武');
                  } else {
                    menu_list = item;
                  }
                  return menu_list;
                })
                .filter((item) => item != null),
            };
            return data;
          });
          // console.log(that.menu_list, local.get("company_id"), "测试");

          that.menu_list = that.menu_list;
          // console.log(that.menu_list, 100);

          if (this.user_region == "5117") {
            if (
              that.menu_list[0].menu_list[0].menu_name == "川冷链追溯监控大屏"
            ) {
              that.menu_list[0].menu_list[0].menu_name = "监管大屏";
            }
          }
          local.set("menu_list", that.menu_list);
          // _this.menu_list.forEach(item => {
          //   item.menu_list.forEach((item1, index) => {
          //     let menu = item1.menu_url.split("/admin/")[1]
          //     this.$set(this.navConfig, menu, item1.page_id)
          //   })
          // })
        }
        // + '?company_id=' + params
      });
    },
    //获取企业信息
    getCompany(params) {
      axios.get("/pc/company/list", { pager_openset: "50" }).then((v) => {
        var obj = {};
        obj = v.data.company_list.find(function (item) {
          return item.company_id == params;
        });
        this.company_name = obj.company_name;
        local.set("company_name", obj.company_name);
      });
    },
    //普通职员获取企业信息company/single
    getCompany1(params) {
      axios.get("/pc/company/single", { company_id: params }).then((v) => {
        this.company_name = v.data.company.company_name;
        local.set("company_name", v.data.company.company_name);
      });
    },
    handleClick() {
      if (this.activeName == "2") {
        var obj = new WxLogin({
          self_redirect: false,
          id: "weixin",
          appid: "wx02bfca0fe5505c6d",
          scope: "snsapi_login",
          redirect_uri: encodeURI("http://efr.foodtrace.org.cn/pc/about"),
          state: "",
          style: "",
          href: "",
        });
      }
    },
    loginWx(params) {
      axios
        .post("/pc/wx/login", { code: params })
        .then((v) => {
          local.set("token", v.data.token);
          axios.get("/pc/user/single").then((response) => {
            this.user = response.data.user;
            local.set("user", response.data.user);
            local.set("company_id", response.data.user.last_login_company_id);
            if (response.data.user.user_type == 3) {
              if (v.data.complete_state == "234") {
                this.isMenu = 1;
                local.set("isMenu", 1);
                this.$router.push({ name: "Public" });
              } else if (v.data.complete_state == "235") {
                this.isMenu = 2;
                local.set("isMenu", 2);
                this.getCompany(
                  String(response.data.user.last_login_company_id)
                );
                this.getMenu(response.data.user.last_login_company_id);
              }
            } else if (response.data.user.user_type == 4) {
              this.isMenu = 2;
              local.set("isMenu", 2);
              this.getCompany1(
                String(response.data.user.last_login_company_id)
              );
              this.getMenu(response.data.user.last_login_company_id);
            } else {
              this.isMenu = 2;
              local.set("isMenu", 2);
              this.getMenu(response.data.user.last_login_company_id);
            }
          });
        })
        .catch((v) => {
          this.$message.error("网络异常");
        });
    },
  },
};
</script>
<style lang="scss" >
#particles-js {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.5;
}
.bg {
  background: url(../assets/image/loginBg.jpg) no-repeat;
  background-size: 100% 100%;
}
.wp {
  background: url(../assets/image/bgImg.png) no-repeat center 0;
  background-size: auto 100%;
  position: relative;
  z-index: 2;
}

.login {
  height: 100vh;
  display: flex;
  //background: #2d3a4b;
  align-items: center;
  //background-size: cover;
  position: relative;
  .el-tabs {
    z-index: 2;
    background: rgba(255, 255, 255, 1);
    margin: auto;
    i {
      vertical-align: bottom;
      color: inherit;
    }
    .el-tabs__nav {
      width: 100%;
      .el-tabs__item {
        width: 100%;
        height: 55px;
        line-height: 55px;
      }
      .el-tabs__item.is-active {
        color: #409eff;
        border-bottom: 2px solid #409eff;
      }
    }
  }
  .contain {
    margin: auto;
    img {
      width: 50%;
    }
  }
  .login-contain {
    width: 500px;
    background-size: contain;
    margin: 0 auto;
    .contain_right {
      width: 80%;
      padding-top: 3%;
      margin: auto;
      padding-bottom: 30px;
    }
    .el-form--label-top .el-form-item__label {
      padding-bottom: 0px;
      color: #409eff;
    }
    .el-form-item.is-error .el-input__inner,
    .el-form-item.is-error .el-input__inner:focus,
    .el-form-item.is-error .el-textarea__inner,
    .el-form-item.is-error .el-textarea__inner:focus,
    input::-webkit-input-placeholder,
    .title {
      color: #409eff;
    }
  }
  input.el-input__inner {
    color: #7f7f7f;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    background-color: rgba(21, 64, 195, 0.06);
  }
  /deep/ .el-input-group--append {
    .el-input__inner {
      border-radius: 4px 0px 0px 4px;
    }
  }
  .el-button.is-round {
    background-color: #409eff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }
  .el-input-group__append {
    background-color: #409eff;
    border: 0px solid #e0e0e0;
    color: #fff;
    border-radius: 0px 4px 4px 0px;
  }

  input:-internal-autofill-previewed,
  input:-internal-autofill-selected {
    //color: #409eff;
    -webkit-text-fill-color: #fff;
    transition: background-color 5000s ease-out 0.5s;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .forget_btn {
    color: #409eff;
  }
  /deep/ .impowerBox .qrcode {
    width: 200px;
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  background-color: none;
}
//表单样式
.boardLogin {
  width: 20%;
  min-width: 200px;
  margin: 0 auto;
  //position: relative;
  top: 50%;
  //-webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  //transform: translateY(-50%);
  .login_title {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .col-f2e {
    color: #f2eada;
  }
}

.boardLogin .inpGroup {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 14px 24px 14px 52px;
  background: #2a658a;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 35px;
  position: relative;
  .button_code {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    background-color: #ffffff;
    color: #f2eada;
    background-color: #0e6f98;
    height: 100%;
    border-radius: 0 30px 30px 0;
    width: 30%;
    span {
      display: block;
      width: 100%;
      left: 0;
    }
  }
}
.boardLogin .inpGroup span {
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
  position: absolute;
  //top: 14px;
  left: 24px;
}
.boardLogin .inpGroup span.loginIco1 {
  background: url(../assets/image/s_ico6.png) no-repeat center;
  background-size: 100% 100%;
}
.boardLogin .inpGroup span.loginIco2 {
  background: url(../assets/image/s_ico7.png) no-repeat center;
  background-size: 100% 100%;
}
.boardLogin .inpGroup input {
  font-size: 16px;
  display: block;
  width: 100%;
  height: 25px;
  line-height: 25px;
  color: #fff;
  background: none;
  margin-left: 10px;
}
//.boardLogin .inpGroup input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
//  color: #fff; opacity:1;
//}
//
//.boardLogin .inpGroup input::-moz-placeholder { /* Mozilla Firefox 19+ */
//  color: #fff;opacity:1;
//}

.boardLogin .inpGroup input:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

.boardLogin .inpGroup input::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}
.boardLogin .prompt {
  min-height: 20px;
  padding: 5px 24px 10px 24px;
}
.boardLogin p {
  line-height: 16px;
  font-size: 16px;
  display: none;
}
.boardLogin p.error {
  color: #f60;
}
.boardLogin p.success {
  color: #449d44;
}
.boardLogin .submit {
  margin-top: 35px;
  width: 100%;
  box-sizing: border-box;
  display: block;
  height: 40px;
  line-height: 38px;
  text-align: center;
  border: 1px solid #01d1f2;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.boardLogin .submit:hover {
  background: #01d1f2;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
//@media screen and (max-width: 1919px) and (min-width: 1680px){
//  .boardLogin .logo{
//    margin-bottom: 38px;
//  }
//  .boardLogin .inpGroup{
//    height: 30px;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    padding: 12px 22px 12px 50px;
//    border-radius: 22px;
//  }
//  .boardLogin .inpGroup span{
//    width: 20px;
//    height: 20px;
//    top: 12px;
//    left: 22px;
//  }
//  .boardLogin .prompt{
//    min-height: 20px;
//    padding: 5px 22px 10px 22px;
//  }
//  .boardLogin p{
//    line-height: 16px;
//
//  }
//  .boardLogin .submit{
//    height: 38px;
//    line-height: 36px;
//    border-radius: 20px;
//  }
//}
//
//@media screen and (max-width: 1679px) and (min-width: 1440px){
//  .boardLogin .logo{
//    margin-bottom: 34px;
//  }
//  .boardLogin .inpGroup{
//    padding: 8px 18px 8px 48px;
//    border-radius: 18px;
//  }
//  .boardLogin .inpGroup span{
//    width: 18px;
//    height: 18px;
//    top: 8px;
//    left: 18px;
//  }
//  .boardLogin .inpGroup span.loginIco1{
//    background: url(../assets/image/s_ico6.png);
//    background-size: 100% 100%;
//  }
//  .boardLogin .inpGroup span.loginIco2{
//    background: url(../assets/image/s_ico7.png);
//    background-size: 100% 100%;
//  }
//  .boardLogin .prompt{
//    min-height: 20px;
//    padding: 5px 18px 10px 18px;
//  }
//  .boardLogin p{
//    line-height: 16px;
//
//  }
//  .boardLogin .submit{
//    height: 36px;
//    line-height: 34px;
//    border-radius: 18px;
//  }
//}
//
//@media screen and (max-width: 1439px){
//  .boardLogin .logo{
//    margin-bottom: 30px;
//  }
//  .boardLogin .inpGroup{
//    padding: 6px 16px 6px 46px;
//    border-radius: 16px;
//  }
//  .boardLogin .inpGroup span{
//    width: 18px;
//    height: 18px;
//    top: 6px;
//    left: 16px;
//  }
//  .boardLogin .inpGroup span.loginIco1{
//    background: url(../assets/image/s_ico6.png);
//    background-size: 100% 100%;
//  }
//  .boardLogin .inpGroup span.loginIco2{
//    background: url(../assets/image/s_ico7.png);
//    background-size: 100% 100%;
//  }
//  .boardLogin .prompt{
//    min-height: 20px;
//    padding: 2px 16px 6px 16px;
//  }
//  .boardLogin p{
//    line-height: 16px;
//
//  }
//  .boardLogin .submit{
//    height: 32px;
//    line-height: 30px;
//    border-radius: 16px;
//  }
//}
</style>
